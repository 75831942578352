import React, { useCallback, useEffect, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  Label,
  StackItem,
  TextField,
  Text,
  ComboBox,
  IComboBoxOption,
  IComboBox,
  Toggle,
  Dropdown,
  Link,
  Icon,
  IDropdownOption,
  IStackItemStyles,
  getTheme,
  PrimaryButton,
} from '@fluentui/react';
import _, { unescape } from 'lodash';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import {
  ADD_PURCHASE_ORDER,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_PURCHASE_ORDER_BY_ID,
  IConstructionSite,
  IMeasuringUnit,
  IPurchaseOrder,
  IPurchaseOrderLine,
  IPurchaseOrderLineCreate,
  IPurchaseOrderLineUpdate,
  IPurchaseOrderStatus,
  IPurchaseOrderTemplate,
  ISupplier,
  UPDATE_PURCHASE_ORDER,
  convertConstructionSitesToComboBoxOptions,
  convertContactPersonsToComboBoxOptions,
  convertMeasuringUnitsToComboBoxOptions,
  convertPurchaseOrderTemplatesToComboBoxOptions,
  convertSuppliersToComboBoxOptions,
  dialogModelProps,
  iconLastProps,
  modalContentStyles,
  stackTokens5,
} from '../../../utils';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { useAppDispatch } from '../../../redux/hooks';
import { throwError, SeverityLevel } from '../../../redux/error/errorSlice';
import { sendNotification } from '../../../redux/notification/notificationSlice';
import parseNumber from '../../../utils/Numbers';
import {
  IPurchaseOrderLineDelete,
  getDefaultLineItem,
} from '../../../utils/PurchaseOrderLine';
import {
  ComboboxWithFilter,
  InlineEditableLabel,
} from '../../../components/parts';
import DraggableList from '../../../components/parts/DraggableList';
import { toastSuccess, toastError } from '../../../utils/toast';
import { customPanelStyles } from '../../../theme';
import PurchaseOrderFooter from './PurchaseOrderFooter';

const theme = getTheme();
const styleTitle: IStackItemStyles = {
  root: {
    background: theme.palette.neutralLight,
    padding: '10px',
    margin: '0 24px 24px 24px',
    marginTop: '15px',
    marginBottom: '15px',
    paddingRight: '20px',
    alignItems: 'center',
    color: 'black',
    fontSize: '16px',
    fontWeight: 'bold',
    h3: {
      margin: 0,
    },
  },
};

const addEmptyOrderLine = (purchaseOrder: IPurchaseOrder) => {
  const newPurchaseOrder = _.cloneDeep(purchaseOrder);
  const purchaseOrderLines = newPurchaseOrder.line_items || [];

  if (!newPurchaseOrder.line_items) {
    newPurchaseOrder.line_items = [];
  }
  if (newPurchaseOrder && newPurchaseOrder.line_items.length === 0) {
    newPurchaseOrder.line_items.push(getDefaultLineItem({}));
  } else if (newPurchaseOrder.line_items) {
    const arrayLength = newPurchaseOrder.line_items.length;
    const lastItem = newPurchaseOrder.line_items[arrayLength - 1];
    if ((lastItem && !lastItem.isNew) || (lastItem.isNew && lastItem.isDirty)) {
      const newWeight = (lastItem.weight || 0) + 1;
      newPurchaseOrder.line_items.push(
        getDefaultLineItem({ weight: newWeight }),
      );
    }
  }

  newPurchaseOrder.line_items = purchaseOrderLines;
  return newPurchaseOrder;
};

export const sortLineItems = (data: IPurchaseOrder) => {
  const newPurchaseOrder = _.cloneDeep(data);
  if (newPurchaseOrder.line_items) {
    newPurchaseOrder.line_items.sort(
      (a, b) => (a.weight || 0) - (b.weight || 0),
    );
  } else {
    newPurchaseOrder.line_items = [];
  }

  for (let i = 0; i < newPurchaseOrder.line_items.length; i++) {
    const currentWeight = newPurchaseOrder.line_items[i].weight;
    newPurchaseOrder.line_items[i].weight = i + 1;
    if (currentWeight !== newPurchaseOrder.line_items[i].weight) {
      newPurchaseOrder.line_items[i].isDirty = true;
    }
  }

  return newPurchaseOrder;
};

type Props = {
  downloadPDF: (id?: number) => void;
  isOpen: boolean;
  dismissPanel: () => void;
  purchaseOrderSource?: IPurchaseOrder;
  constructionSites?: IConstructionSite[];
  templates?: IPurchaseOrderTemplate[];
  suppliers?: ISupplier[];
  statuses?: IPurchaseOrderStatus[];
  measuringUnits?: IMeasuringUnit[];
  constructionSiteSearch?: string;
  setConstructionSiteSearch: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  supplierSearch?: string;
  setSupplierSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
  refetch: any;
  setSelectionDetails: any;
  fast?: boolean;
};

const PurchaseOrderDetail: React.FC<Props> = ({
  downloadPDF,
  isOpen,
  dismissPanel,
  purchaseOrderSource,
  constructionSites = [],
  templates = [],
  suppliers = [],
  statuses = [],
  measuringUnits = [],
  constructionSiteSearch,
  supplierSearch,
  setConstructionSiteSearch,
  setSupplierSearch,
  refetch,
  setSelectionDetails,
  fast,
}) => {
  const dispatch = useAppDispatch();
  const [purchaseOrder, setPurchaseOrder] = useState<
    IPurchaseOrder | undefined
  >(purchaseOrderSource ? sortLineItems(purchaseOrderSource) : undefined);
  const [template, setTemplate] = useState<
    IPurchaseOrderTemplate | undefined
  >();
  const [isDraggable, setIsDraggable] = useState(false);

  useEffect(() => {
    setPurchaseOrder(
      purchaseOrderSource ? sortLineItems(purchaseOrderSource) : undefined,
    );
    setIsDraggable(false); // reset on data change
  }, [purchaseOrderSource]);

  useEffect(() => {
    console.log(purchaseOrder);

    const currentPurchaseOrder = _.cloneDeep(purchaseOrder || {});

    console.log(currentPurchaseOrder);

    const newPurchaseOrder = addEmptyOrderLine(currentPurchaseOrder);

    if (
      JSON.stringify(currentPurchaseOrder) !== JSON.stringify(newPurchaseOrder)
    ) {
      setPurchaseOrder(newPurchaseOrder);
    }
  }, [purchaseOrder]);

  useQuery(GET_PURCHASE_ORDER_BY_ID, {
    variables: {
      where: {
        id: purchaseOrderSource?.id,
      },
    },
    skip: !purchaseOrderSource || !purchaseOrderSource.id,
    onCompleted: (x: any) => {
      if (x && x.findOnePurchaseOrder) {
        let purchaseOrder: IPurchaseOrder = x.findOnePurchaseOrder;
        purchaseOrder = sortLineItems(purchaseOrder);
        setPurchaseOrder(purchaseOrder);
      }
    },
  });

  // Queries
  const [addPurchaseOrder] = useMutation(ADD_PURCHASE_ORDER, {});
  const [updatePurchaseOrder] = useMutation(UPDATE_PURCHASE_ORDER, {});

  // Functions
  const changeTemplate = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined,
    // index?: number | undefined,
    // value?: string | undefined,
  ) => {
    const selectedKey = +option!.key;

    if (selectedKey) {
      const selectedTemplate = templates.filter(
        (item: IPurchaseOrderTemplate) => item.id === +option!.key,
      )[0];

      setTemplate(selectedTemplate);

      const newPurchaseOrder: IPurchaseOrder = {
        remarks:
          selectedTemplate && selectedTemplate.remarks
            ? selectedTemplate.remarks
            : '',
        description: selectedTemplate.description,
        supplier: selectedTemplate.supplier,
        construction_site: purchaseOrder?.construction_site,
        creation_date: moment().toISOString(),
        delivery_to_other_supplier: false,
        is_on_call: false,
        is_purchase_order: true,
        is_default_contact_person: true,
        line_items: [],
        contact_person: selectedTemplate.is_default_contact_person
          ? selectedTemplate.contact_person
          : undefined,
        status: {
          id: 1,
          label: 'Nieuw', // hard coded -> to change @TP
        },
      };
      if (selectedTemplate && selectedTemplate.line_items) {
        const templateLineItems = _.cloneDeep(selectedTemplate.line_items);

        const purchaseOrderLines: IPurchaseOrderLine[] = templateLineItems
          .sort((a, b) => (a.weight || 0) - (b.weight || 0))
          .map((item: IPurchaseOrderLine, index) => ({
            isNew: true,
            isDirty: true,
            id: -1 - index,
            weight: index + 1,
            quantity: item.quantity,
            unit_price: item.unit_price,
            comment: item.comment,
            name: item.name + (item.description ? ` ${item.description}` : ''),
            measuring_unit:
              item.measuring_unit && item.measuring_unit.id
                ? {
                    // probably make this optional? Or store in template?
                    id: item.measuring_unit.id,
                    name: item.measuring_unit.name,
                  }
                : {
                    // probably make this optional? Or store in template?
                    id: 9,
                    name: 'GEEN',
                  },
          }));

        if (newPurchaseOrder.line_items) {
          newPurchaseOrder.line_items = purchaseOrderLines;
        }
      }
      setPurchaseOrder(newPurchaseOrder);
    }
  };

  const setSupplier = useCallback(
    (newValue: IComboBoxOption) => {
      if (suppliers && suppliers.length > 0 && newValue) {
        const index = suppliers.findIndex(
          (item: ISupplier) => item.id === newValue.key,
        );
        const supplier = suppliers[index];
        if (supplier) {
          setPurchaseOrder(prevState => ({
            ...prevState,
            supplier,
          }));
        }
      }
    },
    [purchaseOrder, suppliers],
  );

  const setConstructionSite = useCallback(
    (newValue: IComboBoxOption) => {
      if (constructionSites && constructionSites.length > 0 && newValue) {
        const index = constructionSites.findIndex(
          (item: IConstructionSite) => item.id === newValue.key,
        );
        const constructionSite = constructionSites[index];
        if (constructionSite) {
          setPurchaseOrder(prevState => ({
            ...prevState,
            construction_site: constructionSite,
          }));
        }
      }
    },
    [purchaseOrder, constructionSites],
  );

  // Save and update area
  const getContactPersonValues = (order: IPurchaseOrder) => {
    let result = {};

    if (order.is_default_contact_person) {
      result = {
        ...result,
        is_default_contact_person: true,
      };
    } else if (order.contact_person && order.contact_person.id) {
      result = {
        ...result,
        is_default_contact_person: false,
        contact_person: {
          connect: {
            id: order.contact_person.id,
          },
        },
      };
    }

    return result;
  };

  const getRemarksValues = (order: IPurchaseOrder) => {
    if (order.remarks) return { remarks: order.remarks };
    return { remarks: '' };
  };

  const getDeliveryValues = (order: IPurchaseOrder) => {
    let result = {};

    if (order.delivery_to_other_supplier) {
      if (order.deliver_to_supplier && order.deliver_to_supplier.id) {
        result = {
          ...result,
          deliver_to_supplier: {
            connect: {
              id: order.deliver_to_supplier.id,
            },
          },
        };
      }
    }
    if (order.construction_site && order.construction_site.id) {
      result = {
        ...result,
        construction_site: {
          connect: {
            id: order.construction_site.id,
          },
        },
      };
    }

    return result;
  };

  const createPurchaseOrder = useCallback(async () => {
    if (!purchaseOrder) return;

    try {
      if (purchaseOrder) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            let createValues: any = {
              delivery_date: purchaseOrder.delivery_date,
              description: purchaseOrder.description,
              is_on_call: purchaseOrder.is_on_call,
              pick_up: purchaseOrder.pick_up,
              no_delivery_hour: purchaseOrder.no_delivery_hour,
              on_call_value: purchaseOrder.on_call_value,
              delivery_to_other_supplier:
                purchaseOrder.delivery_to_other_supplier,
              is_purchase_order: purchaseOrder.is_purchase_order,
              construction_site: purchaseOrder.construction_site
                ? {
                    connect: {
                      id: purchaseOrder.construction_site.id,
                    },
                  }
                : undefined,
              supplier:
                purchaseOrder &&
                purchaseOrder.supplier &&
                purchaseOrder.supplier.id
                  ? {
                      connect: {
                        id: purchaseOrder.supplier.id,
                      },
                    }
                  : undefined,
              status: purchaseOrder.status
                ? {
                    connect: {
                      id: purchaseOrder.status.id,
                    },
                  }
                : undefined,
            };

            const contactPersonValues = getContactPersonValues(purchaseOrder);
            const remarksValues = getRemarksValues(purchaseOrder);
            const deliveryValues = getDeliveryValues(purchaseOrder);

            if (
              purchaseOrder &&
              purchaseOrder.construction_site &&
              purchaseOrder.construction_site.id
            ) {
              createValues = {
                ...createValues,
                construction_site: {
                  connect: {
                    id: purchaseOrder.construction_site.id,
                  },
                },
              };
            }

            if (
              purchaseOrder.line_items &&
              purchaseOrder.line_items.length > 0
            ) {
              const created: IPurchaseOrderLineCreate[] = [];
              purchaseOrder.line_items.forEach((item: IPurchaseOrderLine) => {
                if (item.isNew && item.isDirty && !item.delete) {
                  created.push({
                    name: item.name,
                    comment: item.comment ? item.comment : undefined,
                    quantity: parseNumber(item.quantity),
                    unit_price: parseNumber(item.unit_price),
                    measuring_unit: {
                      connect: {
                        id: item.measuring_unit.id,
                      },
                    },
                    weight: item.weight,
                  });
                }
              });

              let lineValues = {};

              if (created.length > 0) {
                lineValues = {
                  ...lineValues,
                  create: created,
                };
              }

              createValues = {
                ...createValues,
                line_items: {
                  ...lineValues,
                },
              };
            }

            addPurchaseOrder({
              variables: {
                data: {
                  ...createValues,
                  ...contactPersonValues,
                  ...remarksValues,
                  ...deliveryValues,
                },
              },
              onCompleted: async (x: any) => {
                refetch();
                resolve(x);
                //  console.log(x.data.findManyPurchaseOrders);
                //  setPurchaseOrder(x.data.findManyPurchaseOrders);
              },
              onError: (error: any) => {
                reject(error);
              },
            });
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        const x = await (res as any);
        if (x.createPurchaseOrder) {
          let newPurchaseOrder = x.createPurchaseOrder;
          newPurchaseOrder = sortLineItems(newPurchaseOrder);
          setSelectionDetails(newPurchaseOrder);
          setPurchaseOrder(newPurchaseOrder);

          console.log(newPurchaseOrder);
          toastSuccess('Inkooporder opgeslagen');
        }
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  }, [purchaseOrder]);

  const modifyPurchaseOrder = useCallback(async () => {
    if (!purchaseOrder) {
      console.log('no purchase order');
      return;
    }
    try {
      if (purchaseOrder) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            let updatedValues = {};

            updatedValues = {
              ...updatedValues,
              description: purchaseOrder.description,
              delivery_date: purchaseOrder.delivery_date,
              is_on_call: purchaseOrder.is_on_call,
              pick_up: purchaseOrder.pick_up,
              no_delivery_hour: purchaseOrder.no_delivery_hour,
              on_call_value: purchaseOrder.on_call_value,
              delivery_to_other_supplier:
                purchaseOrder.delivery_to_other_supplier,
              is_purchase_order: purchaseOrder.is_purchase_order,
              construction_site: purchaseOrder.construction_site
                ? {
                    connect: {
                      id: purchaseOrder.construction_site.id,
                    },
                  }
                : undefined,
              supplier:
                purchaseOrder &&
                purchaseOrder.supplier &&
                purchaseOrder.supplier.id
                  ? {
                      connect: {
                        id: purchaseOrder.supplier.id,
                      },
                    }
                  : undefined,
              status: purchaseOrder.status
                ? {
                    connect: {
                      id: purchaseOrder.status.id,
                    },
                  }
                : undefined,
            };

            const contactPersonValues = getContactPersonValues(purchaseOrder);
            const remarksValues = getRemarksValues(purchaseOrder);
            const deliveryValues = getDeliveryValues(purchaseOrder);

            if (
              purchaseOrder.line_items &&
              purchaseOrder.line_items.length > 0
            ) {
              const created: IPurchaseOrderLineCreate[] = [];
              const updated: IPurchaseOrderLineUpdate[] = [];
              const deleted: IPurchaseOrderLineDelete[] = [];

              purchaseOrder.line_items.forEach((item: IPurchaseOrderLine) => {
                if (item.isNew && !item.delete && item.isDirty) {
                  let lineItem = {};
                  if (item && item.id && item.id > 0) {
                    lineItem = {
                      connect: {
                        id: item.id,
                      },
                    };
                  } else {
                    lineItem = {
                      create: {
                        name: item.name,
                        sku: 'x',
                      },
                    };
                  }

                  created.push({
                    name: item.name,
                    comment: item.comment ? item.comment : undefined,
                    quantity: parseNumber(item.quantity),
                    unit_price: parseNumber(item.unit_price),
                    measuring_unit: {
                      connect: {
                        id: item.measuring_unit.id,
                      },
                    },
                    weight: item.weight,
                  });
                } else if (item.isDirty && !item.delete) {
                  updated.push({
                    data: {
                      name: item.name,
                      comment: item.comment ? item.comment : '',
                      quantity: parseNumber(item.quantity),
                      unit_price: parseNumber(item.unit_price),
                      measuring_unit: {
                        connect: {
                          id: item.measuring_unit.id,
                        },
                      },
                      weight: item.weight,
                    },
                    where: {
                      id: item.id,
                    },
                  });
                } else if (item.delete && !item.isNew) {
                  deleted.push({
                    id: item.id,
                  });
                }
              });

              let lineValues = {};

              if (created.length > 0) {
                lineValues = {
                  ...lineValues,
                  create: created,
                };
              }

              if (updated.length > 0) {
                lineValues = {
                  ...lineValues,
                  update: updated,
                };
              }

              if (deleted.length > 0) {
                lineValues = {
                  ...lineValues,
                  delete: deleted,
                };
              }

              updatedValues = {
                ...updatedValues,
                line_items: {
                  ...lineValues,
                },
              };
            }

            updatePurchaseOrder({
              variables: {
                id: purchaseOrder.id,
                data: {
                  ...updatedValues,
                  ...contactPersonValues,
                  ...remarksValues,
                  ...deliveryValues,
                },
              },
              onCompleted: (x: any) => {
                resolve(x);
              },
              onError: (error: any) => {
                reject(error);
              },
            });
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        const x = await (res as any);
        if (x && x.updatePurchaseOrder) {
          setPurchaseOrder(prevState => {
            let newPurchaseOrder = x.updatePurchaseOrder;
            newPurchaseOrder = sortLineItems(newPurchaseOrder);
            console.log(newPurchaseOrder);
            return newPurchaseOrder;
          });
          toastSuccess('Inkooporder opgeslagen');
        }
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  }, [purchaseOrder]);

  const savePurchaseOrder = useCallback(() => {
    if (purchaseOrder) {
      if (purchaseOrder && purchaseOrder.id && purchaseOrder.id > 0) {
        modifyPurchaseOrder();
      } else {
        createPurchaseOrder();
      }
    }
  }, [purchaseOrder]);

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setPurchaseOrder((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const onDescriptionChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string | undefined,
    ) => {
      const newOrder: IPurchaseOrder = purchaseOrder
        ? _.cloneDeep(purchaseOrder)
        : {};

      newOrder.description = newValue;

      setPurchaseOrder(newOrder);
    },
    [purchaseOrder],
  );

  const onChangeIsPurchaseOrder = useCallback(
    (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
      const newOrder: IPurchaseOrder = purchaseOrder
        ? _.cloneDeep(purchaseOrder)
        : {};
      newOrder.is_purchase_order = checked ?? false;

      setPurchaseOrder(newOrder);
    },
    [purchaseOrder],
  );

  const getTemplatesIncludingNewOption = useCallback(() => {
    const newOption = {
      key: 0,
      text: 'Nieuw',
    };

    return [
      newOption,
      ...convertPurchaseOrderTemplatesToComboBoxOptions(templates),
    ];
  }, [templates]);

  const onStatusChange = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    const newOrder: IPurchaseOrder = purchaseOrder
      ? _.cloneDeep(purchaseOrder)
      : {};
    const statusID = checked ? 2 : 1;
    if (newOrder && statuses) {
      const selectedStatus = statuses.filter(status => {
        if (status.id === statusID) return true;
        return false;
      })[0];
      newOrder.status = selectedStatus;
      setPurchaseOrder(newOrder);
    }
  };

  // combine suppliers with purchaseOrder.suppliers if it doesnt exist in the array
  const getSuppliersIncludingPurchaseOrderSuppliers = useCallback(() => {
    if (purchaseOrder && purchaseOrder.supplier && suppliers) {
      const supplierArray = _.cloneDeep(suppliers);
      const supplier = supplierArray.find(
        (e: ISupplier) => e.id === purchaseOrder.supplier?.id,
      );
      if (!supplier) {
        supplierArray.push(purchaseOrder.supplier);
      }

      supplierArray.sort((obj1: ISupplier, obj2: ISupplier) => {
        let compareName = 0;
        compareName = obj1.name.localeCompare(obj2.name);

        return compareName;
      });

      return supplierArray;
    }
    if (suppliers) {
      const supplierArray = _.cloneDeep(suppliers);
      supplierArray.sort((obj1: ISupplier, obj2: ISupplier) => {
        let compareName = 0;
        compareName = obj1.name.localeCompare(obj2.name);

        return compareName;
      });

      return supplierArray;
    }
    return [];
  }, [suppliers, purchaseOrder]);

  const getConstructionSitesIncludingPurchaseOrderConstructionSite =
    useCallback(() => {
      if (
        purchaseOrder &&
        purchaseOrder.construction_site &&
        constructionSites
      ) {
        const constructionSiteArray = _.cloneDeep(constructionSites);
        const constructionSite = constructionSiteArray.find(
          (e: IConstructionSite) =>
            e.id === purchaseOrder.construction_site?.id,
        );
        if (!constructionSite) {
          constructionSiteArray.push(purchaseOrder.construction_site);
        }

        constructionSiteArray.sort(
          (obj1: IConstructionSite, obj2: IConstructionSite) => {
            let compareName = 0;
            compareName = obj1.name.localeCompare(obj2.name);

            return compareName;
          },
        );

        return constructionSiteArray;
      }
      if (constructionSites) {
        const constructionSiteArray = _.cloneDeep(constructionSites);
        constructionSiteArray.sort(
          (obj1: IConstructionSite, obj2: IConstructionSite) => {
            let compareName = 0;
            compareName = obj1.name.localeCompare(obj2.name);

            return compareName;
          },
        );

        return constructionSiteArray;
      }
      return [];
    }, [suppliers, purchaseOrder, constructionSites]);

  const getContactLabel = useCallback(() => {
    if (
      purchaseOrder &&
      purchaseOrder.supplier &&
      purchaseOrder.supplier.contact_persons &&
      purchaseOrder.is_default_contact_person
    ) {
      return (
        <Label>
          {purchaseOrder.supplier.contact_persons[0]?.first_name}{' '}
          {purchaseOrder.supplier.contact_persons[0]?.last_name}
        </Label>
      );
    }
    if (
      purchaseOrder &&
      purchaseOrder.supplier &&
      purchaseOrder.contact_person &&
      !purchaseOrder.is_default_contact_person
    ) {
      return (
        <Label>
          {purchaseOrder.contact_person.first_name}{' '}
          {purchaseOrder.contact_person.last_name}
        </Label>
      );
    }

    return <Label />;
  }, [purchaseOrder]);

  const getPhoneLabel = useCallback(() => {
    const selectedSupplier =
      purchaseOrder && purchaseOrder.supplier
        ? purchaseOrder.supplier
        : undefined;
    const supplierContactPhone =
      selectedSupplier &&
      selectedSupplier.contact_persons &&
      selectedSupplier.contact_persons[0] &&
      (selectedSupplier.contact_persons[0].phone_v2 ||
        selectedSupplier.contact_persons[0].mobile_v2 ||
        selectedSupplier.contact_persons[0].phone ||
        selectedSupplier.contact_persons[0].mobile)
        ? selectedSupplier.contact_persons[0].phone_v2 ||
          selectedSupplier.contact_persons[0].mobile_v2 ||
          selectedSupplier.contact_persons[0].phone ||
          selectedSupplier.contact_persons[0].mobile
        : undefined;
    return purchaseOrder && purchaseOrder.contact_person ? (
      <Label>
        {purchaseOrder.contact_person.phone_v2 ||
          purchaseOrder.contact_person.mobile_v2 ||
          purchaseOrder.contact_person.phone ||
          purchaseOrder.contact_person.mobile}
      </Label>
    ) : supplierContactPhone ? (
      <Label>{supplierContactPhone}</Label>
    ) : null;
  }, [purchaseOrder]);

  const getFaxLabel = useCallback(() => {
    const selectedSupplier =
      purchaseOrder && purchaseOrder.supplier
        ? purchaseOrder.supplier
        : undefined;
    const supplierContactFax =
      selectedSupplier &&
      selectedSupplier.contact_persons &&
      selectedSupplier.contact_persons[0] &&
      selectedSupplier.contact_persons[0].fax
        ? selectedSupplier.contact_persons[0].fax
        : undefined;
    return purchaseOrder && purchaseOrder.contact_person ? (
      // eslint-disable-next-line react/jsx-indent
      <Label>{purchaseOrder.contact_person.fax}</Label>
    ) : supplierContactFax ? (
      <Label>{supplierContactFax}</Label>
    ) : null;
  }, [purchaseOrder]);

  const loadContactPersons = useCallback(() => {
    const supplier =
      suppliers.find(item => item.id === purchaseOrder?.supplier?.id) ||
      purchaseOrder?.supplier;
    if (supplier) {
      let contactArray: IComboBoxOption[] = [];

      const contactArrayTemp = supplier.contact_persons || [];

      const comboBoxOptions =
        convertContactPersonsToComboBoxOptions(contactArrayTemp);

      if (comboBoxOptions && comboBoxOptions.length > 0) {
        contactArray = contactArray.concat(comboBoxOptions);
      }

      return contactArray;
    }
    return [];
  }, [purchaseOrder, suppliers]);

  const setContactPerson = useCallback(
    (value: IComboBoxOption) => {
      const newOrder: IPurchaseOrder = purchaseOrder
        ? _.cloneDeep(purchaseOrder)
        : {};
      if (newOrder && value) {
        const selectedContact = newOrder.supplier?.contact_persons?.filter(
          contact => {
            if (contact.id === value.key) return true;
            return false;
          },
        )[0];
        newOrder.contact_person = selectedContact;
        newOrder.is_default_contact_person = false;
        setPurchaseOrder(newOrder);
      }
    },
    [purchaseOrder],
  );

  const updateItemValue = (item: any, attribute: string, newValue: string) => {
    // const currentOrder = _.cloneDeep(purchaseOrder);
    const newItem = { ...item };

    newItem[attribute] = newValue;

    newItem.isDirty = true;
    newItem.isEdit = true;

    updateItems(newItem);
  };

  const updateItemMeasuringUnit = (
    item: IPurchaseOrderLine,
    newUnit?: IDropdownOption,
  ) => {
    if (measuringUnits && newUnit) {
      const newMeasuringUnit = measuringUnits.filter(
        (item: IMeasuringUnit) => item.id === newUnit.key,
      )[0];
      const newItem = { ...item };

      newItem.measuring_unit = newMeasuringUnit;
      newItem.isDirty = true;

      updateItems(newItem);
    }
  };

  const updateItems = (item: IPurchaseOrderLine) => {
    const currentOrder = _.cloneDeep(purchaseOrder);
    const newOrderLines = currentOrder ? currentOrder.line_items : [];

    if (newOrderLines) {
      for (let i = 0; i < newOrderLines.length; i++) {
        if (newOrderLines[i].id === item.id) {
          newOrderLines[i] = item;
        }
      }
    }

    if (newOrderLines && currentOrder) {
      currentOrder.line_items = newOrderLines;
      console.log('updateitem', currentOrder);
      setPurchaseOrder(currentOrder);
    }
  };

  const toggleDeleteItem = (id: number) => {
    const currentOrder = _.cloneDeep(purchaseOrder || {});
    const currentOrderLines: IPurchaseOrderLine[] =
      currentOrder && currentOrder.line_items ? currentOrder.line_items : [];

    for (let i = 0; i < currentOrderLines.length; i++) {
      if (currentOrderLines[i].id === id) {
        currentOrderLines[i].delete = !currentOrderLines[i].delete;
        currentOrderLines[i].isDirty = true;
      }
    }

    currentOrder.line_items = currentOrderLines;
    setPurchaseOrder(currentOrder);
  };

  const orderLineItems = (source: number, target: number) => {
    const currentOrder = _.cloneDeep(purchaseOrder || {});
    const currentOrderLines: IPurchaseOrderLine[] =
      currentOrder && currentOrder.line_items ? currentOrder.line_items : [];

    console.log(currentOrderLines);

    const fromIndex = source;
    const toIndex = target;

    const element = currentOrderLines.splice(fromIndex, 1)[0];

    currentOrderLines.splice(toIndex, 0, element);

    for (let i = 0; i < currentOrderLines.length; i++) {
      currentOrderLines[i].weight = i + 1;
      if (!currentOrderLines[i].isNew) {
        currentOrderLines[i].isDirty = true;
      }
    }

    currentOrder.line_items = currentOrderLines;

    setPurchaseOrder(currentOrder);
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        purchaseOrder && purchaseOrder.id
          ? 'Bestelbon wijzigen'
          : 'Bestelbon toevoegen'
      }
      type={PanelType.large}
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {purchaseOrder && purchaseOrder.id && (
            <Label>ID: {purchaseOrder.id}</Label>
          )}
        </div>
        <Stack style={{ padding: '0 24px 24px 24px' }}>
          <TextField
            label='Omschrijving (intern)'
            value={
              purchaseOrder && purchaseOrder.description
                ? purchaseOrder.description
                : ''
            }
            onChange={onDescriptionChange}
          />
        </Stack>
        <Stack
          horizontal
          horizontalAlign='start'
          tokens={{ childrenGap: 30 }}
          style={{ padding: '0 24px 24px 24px', width: '100%' }}
        >
          <Stack.Item tokens={stackTokens5}>
            {purchaseOrder &&
            purchaseOrder.id &&
            purchaseOrder.id > 0 ? null : (
              <Stack horizontal tokens={stackTokens5}>
                <ComboBox
                  selectedKey={template?.id || 0}
                  allowFreeform
                  autoComplete='on'
                  options={getTemplatesIncludingNewOption()}
                  onChange={changeTemplate}
                  label='Sjabloon'
                />
              </Stack>
            )}
          </Stack.Item>
          <Stack.Item tokens={stackTokens5}>
            <Stack horizontal tokens={stackTokens5}>
              <Toggle
                label='Bestelbon | Prijsvraag'
                onText='Bestelbon'
                offText='Prijsaanvraag'
                checked={!!(purchaseOrder && purchaseOrder.is_purchase_order)}
                onChange={onChangeIsPurchaseOrder}
              />
            </Stack>
          </Stack.Item>
          <Stack.Item tokens={stackTokens5}>
            <Stack horizontal tokens={stackTokens5}>
              <Toggle
                label='Nieuw | Verstuurd'
                onText='Verstuurd'
                offText='Nieuw'
                checked={
                  !(
                    !purchaseOrder ||
                    !purchaseOrder.status ||
                    purchaseOrder.status.id === 1
                  )
                }
                onChange={onStatusChange}
              />
            </Stack>
          </Stack.Item>

          <Stack.Item>
            <Label>Aangemaakt op:</Label>
            {purchaseOrder && purchaseOrder.creation_date && (
              <Text style={{ display: 'block' }}>
                {moment(purchaseOrder.creation_date).format('DD-MM-YYYY')}
              </Text>
            )}
          </Stack.Item>
        </Stack>
        <Stack
          horizontal
          horizontalAlign='start'
          tokens={{ childrenGap: 30 }}
          style={{ padding: '0 24px 24px 24px', width: '100%' }}
        >
          <Stack.Item>
            <ComboboxWithFilter
              label='Leverancier'
              options={convertSuppliersToComboBoxOptions(
                getSuppliersIncludingPurchaseOrderSuppliers(),
              )}
              value={
                purchaseOrder && purchaseOrder.supplier
                  ? purchaseOrder.supplier.id
                  : ''
              }
              multiline={false}
              callBack={(newValue: IComboBoxOption[]) => {
                if (newValue && newValue.length > 0) {
                  setSupplier(newValue[0]);
                }
              }}
              setFilter={filterValue => {
                setSupplierSearch(filterValue);
              }}
              allowFreeForm
              style={{ width: '300px' }}
            />
          </Stack.Item>
          <Stack.Item>
            {purchaseOrder &&
              purchaseOrder.supplier &&
              purchaseOrder.supplier.id && (
                <>
                  <Text
                    style={{
                      display: 'block',
                      fontWeight: 'bold',
                      marginBottom: '10px',
                    }}
                  >
                    {' '}
                    {purchaseOrder.supplier.name}{' '}
                  </Text>

                  <Text style={{ display: 'block' }}>
                    {purchaseOrder.supplier.address}
                  </Text>
                  <Text style={{ display: 'block' }}>
                    {purchaseOrder.supplier.zip_code}{' '}
                    {purchaseOrder.supplier.city}
                  </Text>
                  <Link href={`mailto:${purchaseOrder.supplier.email}`}>
                    {purchaseOrder.supplier.email}
                  </Link>
                </>
              )}
          </Stack.Item>
        </Stack>
        <Stack
          horizontal
          horizontalAlign='start'
          tokens={{ childrenGap: 30 }}
          style={{ padding: '0 24px 24px 24px', width: '100%' }}
        >
          <Stack.Item>
            <ComboboxWithFilter
              label='Contactpersoon'
              options={loadContactPersons()}
              value={
                purchaseOrder && purchaseOrder.contact_person
                  ? purchaseOrder.contact_person.id
                  : ''
              }
              multiline={false}
              callBack={(newValue: IComboBoxOption[]) => {
                if (newValue && newValue.length > 0) {
                  setContactPerson(newValue[0]);
                }
              }}
              style={{ width: '300px' }}
            />
          </Stack.Item>
          <Stack.Item>
            <Text
              style={{
                display: 'block',
                fontWeight: 'bold',
                // marginBottom: '10px',
              }}
            >
              {' '}
              Contactpersoon
            </Text>
            <Text style={{ display: 'block' }}>{getContactLabel()}</Text>
            <Text style={{ display: 'block' }}>{getPhoneLabel()}</Text>
            <Text style={{ display: 'block' }}>{getFaxLabel()}</Text>
          </Stack.Item>
        </Stack>
        <Stack
          horizontal
          horizontalAlign='start'
          tokens={{ childrenGap: 30 }}
          style={{ padding: '0 24px 24px 24px', width: '100%' }}
        >
          <Stack.Item>
            <ComboboxWithFilter
              label='Werf'
              options={convertConstructionSitesToComboBoxOptions(
                getConstructionSitesIncludingPurchaseOrderConstructionSite(),
              )}
              value={
                purchaseOrder && purchaseOrder.construction_site
                  ? purchaseOrder.construction_site.id
                  : ''
              }
              multiline={false}
              callBack={(newValue: IComboBoxOption[]) => {
                if (newValue && newValue.length > 0) {
                  setConstructionSite(newValue[0]);
                }
              }}
              setFilter={filterValue => {
                setConstructionSiteSearch(filterValue);
              }}
              allowFreeForm
              style={{ width: '300px' }}
            />
          </Stack.Item>
          <StackItem>
            {purchaseOrder &&
              purchaseOrder.construction_site &&
              (purchaseOrder.construction_site.name ||
                purchaseOrder.construction_site.address) && (
                <>
                  <Text style={{ display: 'block', fontWeight: 'bold' }}>
                    {purchaseOrder.construction_site.name}
                  </Text>
                  <Text style={{ display: 'block' }}>
                    {purchaseOrder.construction_site.address}
                  </Text>
                  <Text style={{ display: 'block' }}>
                    {purchaseOrder.construction_site.zip_code}{' '}
                    {purchaseOrder.construction_site.city}
                  </Text>

                  <Text
                    style={{
                      display: 'block',
                      maxWidth: '350px',
                      marginTop: '10px',
                    }}
                  >
                    {purchaseOrder.construction_site.comments}
                  </Text>
                </>
              )}
          </StackItem>
          {purchaseOrder &&
            purchaseOrder.construction_site &&
            purchaseOrder.construction_site.project_manager && (
              <Stack.Item style={{ marginTop: 20 }}>
                <Label>Projectbeheerder:</Label>
                <Text>
                  {purchaseOrder.construction_site.project_manager.first_name}{' '}
                  {purchaseOrder.construction_site.project_manager.last_name}
                  <br />
                  {purchaseOrder.construction_site.project_manager.mobile}
                </Text>
              </Stack.Item>
            )}
        </Stack>
        <Stack
          horizontal
          tokens={stackTokens5}
          style={{ padding: '0 24px 0px 24px', width: '100%' }}
        >
          <Toggle
            label='Volgorde wijzigen'
            checked={isDraggable}
            onChange={(e, checked) => setIsDraggable(!!checked)}
          />
        </Stack>
        {purchaseOrder &&
          purchaseOrder.line_items &&
          purchaseOrder.line_items.length > 0 && (
            <Stack
              styles={styleTitle}
              horizontal
              horizontalAlign='space-between'
            >
              <Stack.Item>
                <h3>Details</h3>
              </Stack.Item>
            </Stack>
          )}
        <Stack
          horizontal
          horizontalAlign='start'
          tokens={{ childrenGap: 30 }}
          style={{ padding: '0 24px 24px 24px', width: '100%' }}
        >
          {purchaseOrder &&
            purchaseOrder.line_items &&
            purchaseOrder.line_items.length > 0 && (
              <Stack>
                <Stack
                  horizontal
                  style={{
                    width: '100%',
                    borderBottom: '1px solid rgb(229, 229, 229);',

                    textAlign: 'left',
                  }}
                >
                  <Stack.Item
                    style={{
                      textAlign: 'center',
                      width: '75px',
                      fontWeight: 'bold',
                    }}
                  >
                    Regel
                  </Stack.Item>
                  <Stack.Item
                    style={{
                      paddingLeft: '5px',
                      paddingRight: '5px',
                      width: '350px',
                      fontWeight: 'bold',
                    }}
                  >
                    Omschrijving
                  </Stack.Item>
                  <Stack.Item
                    style={{
                      paddingLeft: '5px',
                      paddingRight: '5px',
                      width: '100px',
                      fontWeight: 'bold',
                    }}
                  >
                    Aantal
                  </Stack.Item>
                  <Stack.Item
                    style={{
                      paddingLeft: '5px',
                      paddingRight: '5px',
                      width: '100px',
                      fontWeight: 'bold',
                    }}
                  >
                    Eenheid
                  </Stack.Item>
                  <Stack.Item
                    style={{
                      paddingLeft: '5px',
                      paddingRight: '5px',
                      width: '100px',
                      fontWeight: 'bold',
                    }}
                  >
                    Prijs
                  </Stack.Item>
                  <Stack.Item
                    grow={1}
                    style={{
                      paddingLeft: '5px',
                      paddingRight: '5px',
                      fontWeight: 'bold',
                    }}
                  >
                    Opmerking
                  </Stack.Item>
                  <Stack.Item style={{ textAlign: 'center', width: '75px' }} />
                </Stack>
                <DraggableList
                  draggable={isDraggable}
                  onDrop={(source, target) => {
                    orderLineItems(source, target);
                  }}
                >
                  {purchaseOrder &&
                    purchaseOrder.line_items.length > 0 &&
                    purchaseOrder.line_items.map(
                      (item: IPurchaseOrderLine, index: number) => {
                        const fieldsPerRow = 5;
                        const currentFieldIndex = index * fieldsPerRow + 1;
                        return (
                          <Stack
                            horizontal
                            style={{
                              width: '100%',
                              marginTop: '20px',
                              paddingTop: '5px',
                              paddingBottom: '5px',
                              // '&:hover': { background: 'rgb(239, 239, 239)' },
                            }}
                            styles={{
                              root: {
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                '&:hover': {
                                  background: 'rgb(239, 239, 239)',
                                },
                              },
                            }}
                            key={`${item.id}_${index}`}
                          >
                            <Stack.Item
                              style={{
                                textAlign: 'center',
                                width: '75px',
                                opacity: item.delete ? 0.5 : 1,
                              }}
                            >
                              <span>{item.weight}</span>
                            </Stack.Item>
                            <Stack.Item
                              style={{
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                width: '350px',
                                opacity: item.delete ? 0.5 : 1,
                              }}
                            >
                              <InlineEditableLabel
                                isMultiline
                                isEdit
                                value={item.name || ''}
                                callBack={(value: string) =>
                                  updateItemValue(item, 'name', value)
                                }
                                tabIndex={currentFieldIndex + 1}
                              />
                            </Stack.Item>
                            <Stack.Item
                              style={{
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                width: '100px',
                                opacity: item.delete ? 0.5 : 1,
                              }}
                            >
                              <InlineEditableLabel
                                isEdit
                                value={item.quantity || ''}
                                type='text'
                                callBack={(value: string) =>
                                  updateItemValue(item, 'quantity', value)
                                }
                                tabIndex={currentFieldIndex + 2}
                                noScroll
                              />
                            </Stack.Item>
                            <Stack.Item
                              style={{
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                width: '100px',
                                opacity: item.delete ? 0.5 : 1,
                              }}
                            >
                              <Dropdown
                                selectedKey={
                                  item && item.measuring_unit
                                    ? item.measuring_unit.id
                                    : undefined
                                }
                                options={
                                  measuringUnits
                                    ? convertMeasuringUnitsToComboBoxOptions(
                                        measuringUnits,
                                      )
                                    : []
                                }
                                onChange={(
                                  event: React.FormEvent<HTMLDivElement>,
                                  newUnit?: IDropdownOption,
                                ) => {
                                  updateItemMeasuringUnit(item, newUnit);
                                }}
                                tabIndex={currentFieldIndex + 3}
                              />
                            </Stack.Item>
                            <Stack.Item
                              style={{
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                width: '100px',
                                opacity: item.delete ? 0.5 : 1,
                              }}
                            >
                              <InlineEditableLabel
                                isEdit
                                type='text'
                                value={item.unit_price || undefined}
                                prefix='€'
                                callBack={(value: string) =>
                                  updateItemValue(item, 'unit_price', value)
                                }
                                tabIndex={currentFieldIndex + 4}
                                noScroll
                              />
                            </Stack.Item>
                            <Stack.Item
                              grow={1}
                              style={{
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                opacity: item.delete ? 0.5 : 1,
                              }}
                            >
                              <InlineEditableLabel
                                isEdit
                                value={item.comment || undefined}
                                callBack={(value: string) =>
                                  updateItemValue(item, 'comment', value)
                                }
                                tabIndex={currentFieldIndex + 5}
                              />
                            </Stack.Item>
                            <Stack.Item
                              style={{
                                textAlign: 'center',
                                width: '75px',
                                opacity: 1,
                              }}
                            >
                              <Icon
                                iconName='Delete'
                                styles={iconLastProps}
                                onClick={() => {
                                  toggleDeleteItem(item.id);
                                }}
                              />
                            </Stack.Item>
                          </Stack>
                        );
                      },
                    )}
                </DraggableList>
              </Stack>
            )}
        </Stack>
        <PurchaseOrderFooter
          purchaseOrder={purchaseOrder}
          setPurchaseOrder={setPurchaseOrder}
          constructionSite={purchaseOrder?.construction_site}
          suppliers={suppliers}
          setSupplierFilter={setSupplierSearch}
        />
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              <SaveButtonWithPermissions
                disabled={
                  !purchaseOrder ||
                  !purchaseOrder.supplier ||
                  !purchaseOrder.supplier.id
                }
                save={savePurchaseOrder}
                permission='write:purchaseOrders'
              />

              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>
            <Stack>
              {purchaseOrder?.id && (
                <PrimaryButton
                  onClick={() => {
                    downloadPDF();
                  }}
                >
                  Download PDF <Icon iconName='PDF' />
                </PrimaryButton>
              )}
            </Stack>

            {/* <Stack>
              <StackItem>
                <DefaultButton onClick={toggleConfirmationDialog}>
                  Verwijderen
                </DefaultButton>
              </StackItem>
            </Stack>
            */}
          </Stack>
        </div>
      </div>

      {/* <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemoveConfirmationPropsGeneral}
        modalProps={dialogModelProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteLogItemFn} text='Verwijderen' />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
        */}
    </Panel>
  );
};

export default PurchaseOrderDetail;
