import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  Stack,
} from '@fluentui/react';
import { toast } from 'react-toastify';
import { useBoolean } from '@fluentui/react-hooks';
import { useParams } from 'react-router';

import {
  ADD_MAIL_TEMPLATE,
  DELETE_MAIL_TEMPLATE,
  GET_MAIL_TEMPLATE_BY_ID,
  IMailTemplate,
  UPDATE_MAIL_TEMPLATE,
} from '../../utils/MailTemplate';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
} from '../../utils';
import { toastError, toastSuccess } from '../../utils/toast';
import { commandBarTheme, commandBarThemeCounter } from '../../theme';
import { CommandBarSticky } from '../../components/parts';
import MailTemplateDetail from './MailTemplateDetail';
import MailTemplateDetailsList from './MailTemplateDetailList';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  templates: IMailTemplate[];
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  showLoadMore?: boolean;
  refetch?: any;
}

const MailTemplatesOverview = ({
  templates,
  loading,
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
  refetch,
}: Props) => {
  const [template, setTemplate] = useState<IMailTemplate | undefined>(
    undefined,
  );
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [deleteDialogOpen, { toggle: toggleDelete }] = useBoolean(false);
  const [addTemplate] = useMutation(ADD_MAIL_TEMPLATE);
  const [modifyTemplate] = useMutation(UPDATE_MAIL_TEMPLATE);
  const [deleteTemplate] = useMutation(DELETE_MAIL_TEMPLATE);

  const { id: planId } = useParams();

  // Selection

  const [selectionDetails, setSelectionDetails] = useState<
    IMailTemplate | undefined
  >(planId ? ({ id: planId } as any) : undefined);

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const { refetch: refetchTemplate } = useQuery(GET_MAIL_TEMPLATE_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: Number(selectionDetails.id) } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setTemplate(data.findOneMailTemplate);
    },
  });

  const openMailTemplateDetail = (newMailTemplate?: boolean) => {
    if (selectionDetails && !newMailTemplate) {
      // getCostCenter({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setTemplate(undefined);
    }
    setIsPanelOpen(true);
  };

  const saveMailTemplate = async () => {
    try {
      if (template) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              active: template?.active === true,
              name: template?.name ? template?.name : undefined,
              comments: template?.comments ? template?.comments : undefined,
              body: template?.body ? template?.body : undefined,
            };

            if (template.id) {
              modifyTemplate({
                variables: {
                  id: template.id,
                  data: allInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  reject(error);
                },
                onCompleted: x => {
                  setIsPanelOpen(false);
                  resolve(x);
                },
              });
            } else {
              addTemplate({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  reject(error);
                },
                onCompleted: x => {
                  resolve(x);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        const x = await (res as any);
        if (x && x.createMailTemplate) {
          setTemplate(x.createMailTemplate);
          toastSuccess('Template succesvol opgeslagen');
          refetch();
        }
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsPanelOpen(false);
    }
  };

  const deleteSelection = useCallback(async () => {
    if (!selectionDetails) {
      toggleDelete();
      return;
    }

    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          deleteTemplate({
            variables: {
              where: {
                id: selectionDetails?.id,
              },
            },
            onError: (error: any) => {
              toggleDelete();
              reject(error);
            },
            onCompleted: async (x: any) => {
              toggleDelete();
              await refetch();
              setTemplate(undefined);
              resolve(x);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      toastSuccess('Voorbeeld project verwijderd');
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_LOADING_MESSAGE);
    }
  }, [selectionDetails]);

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${templates.length} / ${
        templates.length > 0 ? templates[0].prisma_total : 0
      }`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openMailTemplateDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openMailTemplateDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
    {
      key: 'delete',
      text: 'Verwijderen',
      iconProps: { iconName: 'Trash' },
      onClick: () => toggleDelete(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Hooks

  useEffect(() => {
    if (template && isPanelOpen) {
      document.title = `3bouw | Mail Sjabloon - ${template.name}`;
    } else {
      document.title = '3bouw | Mail Sjabloon';
    }
  }, [template, isPanelOpen]);

  useEffect(() => {
    if (planId) {
      setSelectionDetails({ id: planId } as any);
      openMailTemplateDetail();
    }
  }, []);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        maxWidth='2000px'
      />

      <MailTemplateDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
        }}
        template={template}
        saveTemplate={saveMailTemplate}
        setTemplate={setTemplate}
        refetch={refetchTemplate}
      />
      {deleteDialogOpen && selectionDetails && (
        <Dialog
          hidden={!deleteDialogOpen}
          onDismiss={toggleDelete}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Sjabloon verwijderen',
            closeButtonAriaLabel: 'Close',
          }}
        >
          <p>
            <strong>Ben je zeker dat je dit sjabloon wilt verwijderen.</strong>
          </p>
          <p>Deze actie kan niet ongedaan gemaakt worden.</p>
          <DialogFooter>
            <PrimaryButton text='Verwijderen' onClick={deleteSelection} />
            <DefaultButton text='Annuleren' onClick={toggleDelete} />
          </DialogFooter>
        </Dialog>
      )}

      {templates && (
        <>
          <MailTemplateDetailsList
            items={templates}
            selection={selection}
            enableShimmer={!loading}
            isSortedAsc={isSortedAsc}
            sortedField={sortedField}
            setSorting={setSorting}
            loadMore={loadMore}
            lazyLoading={lazyLoading}
            loadMoreCallback={loadMoreCallback}
            loading={loading}
            onItemInvoked={() => openMailTemplateDetail(false)}
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
                // display: items.length === costCenters.length ? 'block' : 'none',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton
                text='Toon meer'
                onClick={() => loadMoreCallback()}
              />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}

      {!loading && !showLoadMore && templates.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default MailTemplatesOverview;
