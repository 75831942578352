import { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
 IconButton, Panel, PanelType, PrimaryButton, Stack,
} from '@fluentui/react';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useAppDispatch } from '../../../redux/hooks';
import {
  GET_CUSTOMER_INVOICE_BY_ID,
  ICustomerInvoice,
} from '../../../utils/CustomerInvoice';
import { cancelIcon, iconButtonStyles, stackTokens5 } from '../../../utils';
import { SeverityLevel, throwError } from '../../../redux/error/errorSlice';
import { customPanelStyles } from '../../../theme';
import CustomerInvoiceDownloadTemplate from './CustomerInvoiceDownloadTemplate';

const CustomerInvoicesDynamicPdf = ({
  customerInvoiceSource,
  setShowPdf,
}: any) => {
  const dispatch = useAppDispatch();
  const [customerInvoice, setCustomerInvoice] = useState<
    ICustomerInvoice | undefined
  >();

  const {
    data: customerInvoiceDate,
    loading,
    error,
  } = useQuery(GET_CUSTOMER_INVOICE_BY_ID, {
    variables: {
      where: {
        id: customerInvoiceSource.id,
      },
    },
    onError: error => {
      dispatch(
        throwError({
          module: 'customerInvoicePdf',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    onCompleted: (data: any) => {
      setCustomerInvoice(data.findOneCustomerInvoice);
    },
  });

  return (
    <Panel
      isOpen
      onDismiss={() => setShowPdf(false)}
      closeButtonAriaLabel='Close'
      headerText="PDF Bekijken"
      type={PanelType.custom}
      customWidth='100%'
      styles={customPanelStyles}
    >
      {!loading && !error && customerInvoice && (
        <Stack
          styles={{
            root: {
              position: 'fixed',
              top: 60,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'white',
              marginTop: '0!important',
              display: 'flex',
              padding: '20px',
            },
          }}
        >
          <Stack.Item styles={{ root: { flex: 1 } }}>
            <PDFViewer width='100%' height='100%'>
              <CustomerInvoiceDownloadTemplate
                customerInvoice={customerInvoice}
              />
            </PDFViewer>
          </Stack.Item>
        </Stack>
      )}
    </Panel>
  );
};

export default CustomerInvoicesDynamicPdf;
