import {
  DetailsListLayoutMode,
  IColumn,
  IObjectWithKey,
  ISelection,
  SelectionMode,
} from '@fluentui/react';
import { useState } from 'react';
import { IMailTemplate } from '../../utils/MailTemplate';
import { DetailsListDefault } from '../../components/parts';
import Label from '../../components/parts/Label';

interface props {
  items: IMailTemplate[];
  selection?: ISelection<IObjectWithKey> | undefined;
  onItemInvoked?: () => void;
  enableShimmer?: boolean;
  isSortedAsc?: boolean;
  sortedField: string;
  setSorting: (isDesc: boolean, field: string) => void;
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
}

const MailTemplateDetailsList = ({
  items,
  selection,
  onItemInvoked,
  enableShimmer = false,
  isSortedAsc = false,
  sortedField,
  setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
  loadMore,
  lazyLoading,
  loading,
  loadMoreCallback,
}: props) => {
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;
          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);
      setSorting(!sortDescending, fieldName);
    }
  };
  const columnsList = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'name',
      minWidth: 250,
      maxWidth: 250,
      isRowHeader: true,
      isSorted: true,
      onColumnClick,
      onRender: (plan: IMailTemplate) => <span>{plan.name}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Interne Beschrijving',
      fieldName: 'comments',
      minWidth: 200,
      maxWidth: 350,
      isRowHeader: true,
      onColumnClick,
      onRender: (plan: IMailTemplate) => <span>{plan.comments}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Status',
      fieldName: 'active',
      minWidth: 200,

      isRowHeader: true,
      onColumnClick,

      onRender: (plan: IMailTemplate) => (
        <Label color={plan.active ? 'green' : 'red'}>
          {plan.active ? 'Gepubliceerd' : 'Gearchiveerd'}
        </Label>
      ),
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  return (
    <DetailsListDefault
      items={items}
      columns={columns}
      layoutMode={DetailsListLayoutMode.justified}
      selection={selection}
      selectionMode={SelectionMode.single}
      selectionPreservedOnEmptyClick
      onItemInvoked={onItemInvoked}
      enableShimmer={enableShimmer}
      loadMore={loadMore}
      lazyLoading={lazyLoading}
      loadMoreCallback={loadMoreCallback}
      loading={loading}
    />
  );
};

export default MailTemplateDetailsList;
