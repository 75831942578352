import React, { useState } from 'react';
import {
  DefaultButton,
  Modal,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { Editor } from '@tinymce/tinymce-react';

const AppUserModal = ({
  hideModal,
  toggleHideModal,
  modalUser,
  createUser,
}: any) => {
  const [text, setText] = useState(`<p>Beste ${modalUser.first_name1},</p>\n<p>Zoals eerder aangekondigd krijg je naast ons fysieke inspiratieboek ook toegang tot ons online portaal vol plannen van voorbeeldwoningen. <br>Dit is geen keuzemenu, maar een bijkomende inspiratiebron voor jouw maatwerkproject.</p>\n<p>Je kan inloggen via onderstaande link en met het bijgevoegde wachtwoord.</p>`);
  return (
    <Modal
      isOpen={!hideModal}
      onDismiss={toggleHideModal}
      styles={{
        main: {
          paddingTop: 0,
          paddingBottom: 20,
          paddingLeft: 35,
          paddingRight: 35,
          backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white
          backdropFilter: 'blur(10px)', // Adjust blur level as desired
          borderRadius: '8px', // Optional: rounded corners
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: subtle shadow
        },
        scrollableContent: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '550px',
        },
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.4)', // Make overlay transparent
        },
      }}
    >
      <Stack grow={1}>
        <Stack.Item>
          <h2>
            {modalUser.auth0_id
              ? 'Portaal account hersturen'
              : 'Portaal account toevoegen'}
          </h2>
        </Stack.Item>
        <Stack.Item grow={1}>
          <Stack align-items='center' styles={{ root: { paddingTop: 0 } }}>
            {modalUser && (
              <div>
                <div>
                  <TextField
                    name='naam'
                    label='Naam'
                    disabled
                    value={`${modalUser.first_name1} ${modalUser.last_name1}`}
                  />
                </div>
                <div>
                  <StackItem style={{ paddingTop: '' }}>
                    <TextField
                      name='email'
                      label='Email'
                      value={modalUser.email1}
                      disabled
                    />
                  </StackItem>
                </div>
                <div>
                  <StackItem style={{ paddingBottom: '15px' }}>
                    <p><strong>Bericht</strong></p>
                    <Editor
                      apiKey='y8v65wfkd6uxajrh03buxibzkyrxwywwe3ggcccybjg1ry4d'
                      init={{
                        height: 317,
                        menubar: true,
                        menu: {
                          file: { title: 'File', items: 'newdocument' },
                          edit: {
                            title: 'Edit',
                            items:
                              'undo redo | cut copy paste pastetext | selectall',
                          },
                          insert: { title: 'Insert', items: 'link emplate' },
                          format: {
                            title: 'Format',
                            items: 'bold italic underline',
                          },
                        },
                        plugins: [
                          'advlist',
                          'autolink',
                          'lists',
                          'link',
                          'image',
                          'charmap',
                          'preview',
                          'anchor',
                          'searchreplace',
                          'visualblocks',
                          'code',
                          'fullscreen',
                          'insertdatetime',
                          'media',
                          'table',
                          'help',
                          'wordcount',
                        ],
                        toolbar:
                          'undo redo | blocks | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                      }}
                      value={text}
                      onEditorChange={(e: any) => {
                        setText(e);
                      }}
                    />
                    <p><strong>*Email afsluiting en handtekening worden automatisch toegevoegd.*</strong></p>
                  </StackItem>
                </div>
              </div>
            )}
          </Stack>
        </Stack.Item>
        <Stack horizontal>
          <Stack.Item align='end'>
            <PrimaryButton
              text='Versturen'
              styles={{ root: { marginRight: '15px' } }}
              onClick={() => {
                createUser(text);
                toggleHideModal();
              }}
            />
          </Stack.Item>

          <Stack.Item align='end' style={{ paddingTop: '10px' }}>
            <DefaultButton
              onClick={e => {
                e.preventDefault();
                toggleHideModal();
              }}
              text='Annuleren'
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default AppUserModal;
